
function read_cookie(key)
{
    var result;
    return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
}
window.vw = function(v) {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}

$(document).on('turbolinks:load', function () {

  $(".popup-btn").on('click', function () {
    $(".home-popup-wrapper").hide();
    document.cookie = "confirmed= true;";

  })
  if (read_cookie("confirmed")) {
    $(".home-popup-wrapper").hide();
  } 
})
